import { createSlice } from '@reduxjs/toolkit';
import themeConfig from '../configs/themeConfig';

const initialState = {
  themConfig: { ...themeConfig },
};

const themConfigSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSkin(state, action) {
      state.themConfig = { ...state.themConfig, layout: { ...state.themConfig.layout, skin: action.payload } };
      const element = window.document.body;

      // ** Define classnames for skins
      const classNames = {
        dark: "dark-layout",
        bordered: "bordered-layout",
        "semi-dark": "semi-dark-layout",
      };

      // ** Remove all classes from Body on mount
      element.classList.remove(...element.classList);

      // ** If skin is not light add skin class
      if (action.payload !== "light") {
        element.classList.add(classNames[action.payload]);
      }
    },
    setMenuCollapsed(state, action) {
      const classNames = {
        old: state.themConfig.layout.menu.isCollapsed ? "menu-collapsed" : "menu-expanded",
        newValue: action.payload ? "menu-collapsed" : "menu-expanded",
      };
      state.themConfig.layout.menu.isCollapsed = action.payload
      const element = window.document.getElementById("theme-container")
      element.classList.remove(classNames.old);
      element.classList.add(classNames.newValue);
    },
    setLayout(state, action) {
      const element = window.document.getElementById("theme-container")
      element.classList.remove(...element.classList);
      for (const clss of action.payload) {
        if (clss === "") continue;
        element.classList.add(clss);
      }
    },
  },
});

export const themActions = themConfigSlice.actions;
export default themConfigSlice.reducer;