import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import "./core/scss/core.scss";
import "./assets/scss/style.scss";
import './app.scss'
// import './app-custome.scss'

import { configureStore } from '@reduxjs/toolkit';
import themeConfigReducer, {themActions} from './redux/themeConfigSlice';
import {currentUser, login, getToken, logout } from './auth';
const authenticatedUser = login().then((user) => user);
const store = configureStore({
  reducer: {
    layout:themeConfigReducer,
  },
});

const data = {
  props: {
    layout: {store:store,actions:{...themActions}},
    auth: { login, getToken, logout, currentUser },
  },
  loaders: {
    loadingApp: `loading..`,
  },
};
// const [color, setColor] = useState("light")
// let color="light"

// setTimeout(() => {
//   color="dark";
//   store.dispatch(setColor('dark'));
//   console.log(data);
//   // setColor("dark");
// }, 5000);

const routes = constructRoutes(microfrontendLayout, data);
authenticatedUser.then((user) => {
  if (user) {
    // ... Register other microfrontends ...
    const applications = constructApplications({
      routes,
      loadApp({ name }) {
        return System.import(name);
      },
    });
    
      const layoutEngine = constructLayoutEngine({ routes, applications });
      
      applications.forEach(registerApplication);
      layoutEngine.activate();
  }
  start();
});




